<template>
    <apexchart type="donut" height="350" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
//import { mapGetters } from 'vuex'
//import axios from 'axios'

//import { graphUser } from '@/data/api'

export default {
    name: "Donut",

    props: ['query'],

    data(){
        return{
          
            series: [],
            chartOptions: {
                chart: {
                    type: 'donut',
                    height: 350,
                },

                title: {
                    text: "Pomer TK : EK",
                    align: "center"
                },

                labels: ['TK', 'EK'],

                legend: {
                    position: 'right',
                    offsetY: 100,
                    fontSize: '18px'
                },
                noData:{
                    text: "Žiadne dáta"
                },
            },
        }   
    },

    methods:{
        ratioChanged(val){
            this.series = val
        }
    },

    watch:{
        query(val){
            // if empty
            if(Object.keys(val).length == 0){
                this.series = []
                this.chartOptions = {...this.chartOptions, ...{
                    labels: []
                }}
            }else{
                this.ratioChanged(val)
                this.chartOptions = {...this.chartOptions, ...{
                    labels: ["TK", "EK"]
                }}
            }
            
        }
    }


}
</script>