<template>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
export default {
    name: "BarStacked",

    props: ['query', 'title'],

    data() {  
        return{
            all_records: [],
            mesiace: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
            series: [
                {
                    name: 'Spôsobilé',
                    data: []
                }, 
                {
                    name: 'Dočasne spôsobilé',
                    data: []
                }, 
                {
                    name: 'Nespôsobilé',
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%'
                },

                xaxis: {
                    type: 'category',
                },

                fill: {
                    opacity: 1,
                },

                title:{
                    text: this.title,
                    align: 'center',
                    floating: true,
                },

                legend: {
                    position: 'right',
                    offsetX: -10,
                    offsetY: 100,
                },
                noData: {
                    text: 'Žiadne dáta',
                }
            },
        }      
    },

    methods: {
        stackedChanged(value){
            this.all_records = value

            const allDates = Object.keys(this.all_records);
            const lastDate = allDates[allDates.length - 1]

            let dataS = []
            let dataD = []
            let dataN = []

            let xlabels = []
            let date = undefined

            for(let prop in this.all_records){
                date = new Date(prop)

                do{
                    // letny cas ochrana
                    date.setHours(12,0,0,0)

                    if(allDates.includes( date.toISOString().slice(0,7))){
                        dataS.push(this.all_records[prop]['S'])
                        dataD.push(this.all_records[prop]['D'])
                        dataN.push(this.all_records[prop]['N'])
                    }else{
                        dataS.push(0)
                        dataD.push(0)
                        dataN.push(0)
                    }
                    
                    xlabels.push(this.mesiace[date.getMonth()])
                    date.setMonth(date.getMonth() + 1)
                }while( !allDates.includes( date.toISOString().slice(0,7) ) && prop !== lastDate)
            }

            this.chartOptions = {...this.chartOptions, ...{
                xaxis: {
                    type: "category",
                    categories: xlabels
                },
            }}

            this.series = [
                {
                    ...this.series[0],
                    data: dataS
                },
                {
                    ...this.series[1],
                    data: dataD
                },
                {
                    ...this.series[2],
                    data: dataN
                }
            ]
        }
    },

    activated(){
        const cloneData = [...this.series]
        this.series = cloneData
    },

    watch:{
        query(val){
            // if empty
            if(Object.keys(val).length == 0){
                this.chartOptions = {...this.chartOptions, ...{
                    xaxis: {
                        categories: []
                    },
                }}
                
                this.series = [
                {
                    ...this.series[0],
                    data: []
                },
                {
                    ...this.series[1],
                    data: []
                },
                {
                    ...this.series[2],
                    data: []
                }
            ]
            }else{
                this.stackedChanged(val)
            }
            
        }
    }

}
</script>