<template>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
export default {
    name: "BarCount",

    props: ['query'],

    data() {  
        return{
            all_records: [],
            mesiace: ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
            series: [{
                data: []
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '15px',
                    }
                    
                },
                xaxis: {
                    type: 'category',
                },
                title:{
                    text: 'Počet záznamov',
                    align: 'center',
                    floating: true,
                },
                tooltip: {

                    y: {
                        title: {
                            formatter: function () {
                                return ''
                            }
                        }
                    }
                },
                noData: {
                    text: 'Žiadne dáta'
                }
            },
        }      
    },
    
    methods:{
        dataChanged(value){
            this.all_records = value
            
            const allDates = Object.keys(this.all_records);
            const lastDate = allDates[allDates.length - 1]
            //let min = new Date(firstDate)

            let data = []
            let xlabels = []
            let date = undefined

            for(let prop in this.all_records){
                date = new Date(prop)
                // letny cas ochrana
                date.setHours(12,0,0,0)

                do{
                    if (allDates.includes( date.toISOString().slice(0,7) )){
                        data.push(this.all_records[prop])
                    }else{
                        data.push(0)
                    }

                    xlabels.push(this.mesiace[date.getMonth()])
                    date.setMonth(date.getMonth() + 1)

                }while( !allDates.includes( date.toISOString().slice(0,7) ) && prop !== lastDate )
               


            }
            this.chartOptions = {...this.chartOptions, ...{
                xaxis: {
                    categories: xlabels
                },
            }}

            this.series = [{
                data
            }]
        }
        
    },

    activated(){
        const cloneData = [...this.series]
        this.series = cloneData
    },
    watch:{
        query(val){
            // if empty
            if(Object.keys(val).length == 0){
                this.chartOptions = {...this.chartOptions, ...{
                    xaxis: {
                        categories: []
                    },
                }}

                this.series = [{data:[]}]
            }else{
                this.dataChanged(val)
            }
            
        }
    }

}
</script>